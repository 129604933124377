/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'BlenderPro';
  src: url('../public/fonts/fonnts.com-Blender_Pro_Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RalewayRegular';
  src: url('../public/fonts/Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.homeFirstBackgroundImg {
  background-image: url('/public/images/angledWhiteGrayBG.png');
}

.newestHomeGraphImg {
  background-image: url('/public/images/stock_home4.png');
}

.intellichargeBackgroundImg {
  background-image: url('/public/images/chatGPTevchargersdark.jpg');
}

.midaasBackgroundImg {
  background-image: url('/public/images/damPicture2.jpg');
}

.recognitionBackgroundImg {
  background-image: url('/public/images/windTurbinePic3.jpg');
}

@keyframes slideInFromTop {
  from {
    transform: translateY(-80px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideOutFromTop {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-80px);
  }
}

.slideDown {
  animation: slideInFromTop 0.10s ease-out forwards;
}

.slideUp {
  animation: slideOutFromTop 0.10s ease-out forwards;
}

.homeGradient {
  background: linear-gradient(80deg, #122026, #162825);
}


.large-bold-shadow {
  box-shadow: 3px 4px 0px 0px rgba(55, 170, 151, 0.902);
}

.large-bold-shadow-left {
  box-shadow: -3px 4px 0px 0px rgba(193, 187, 214, 0.902);
}

.large-bold-shadow-right {
  box-shadow: 4px 5px 0px 0px rgba(193, 187, 214, 0.902);
}

.intellichargeGraphic {
  rotate: y 0deg;
}

/* .intellichargeGradient {
  background: linear-gradient(50deg, #001722, #192e38);
} */

/* .large-bold-shadow-left {
  box-shadow: -3px 4px 0px 0px rgba(68, 118, 224, 0.902);
} */